

.Layout{

    .ant-layout-content{
    background: #fff;
    }
    .ant-layout-header {
        position: absolute;
        padding: 0 175px;
        background: none;
        width: 100%;
        min-width: 1280px;
    }
    .ant-layout-footer{
        padding: 0;
    }
}
