@import '~rc-textarea/assets/index.css';
.font-24-fff{
  color: #ffffff;
  font-size: 24px;
}
.font-16-fff{
  color: #ffffff;
  font-size: 16px;
}
.font-18-fff{
  color: #ffffff;
  font-size: 18px;
}
.font-20-black{
  color: #333;
  font-size: 20px;
}.font-16-black{
  color: #333;
  font-size: 16px;
}
.click{
  cursor: pointer;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1280px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


