
.header{
  display: flex;
  justify-content: flex-end;
  &-row{
    width: 800px;
     display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-link{
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    height: 60px;
    line-height: 60px;
    box-sizing: border-box;
    &:hover{
      color: #fff;
      border-bottom:3px solid #6ca4ef;
    }
    position:relative;

  }
}



.ui-box {
  text-decoration:none;
  border:none;
  font-family:'Roboto', sans-serif;
  //font-size:14px;
  position:relative;
  //vertical-align:baseline;
  //padding:10px
}
.bottom-inOutSpread:before,.bottom-inOutSpread:after,.bottom-inOutSpread>.ui-border-element:before,.bottom-inOutSpread>.ui-border-element:after {
  content:'';
  position:absolute
}
.bottom-inOutSpread:after {
  border-bottom:3px solid #6ca4ef;

  left:51%;
  right:51%;
  bottom:-1px;
  -webkit-transition-property:all;
  -moz-transition-property:all;
  -o-transition-property:all;
  transition-property:all;
  -webkit-transition-timing-function:cubic-bezier(0, 0.98, 0.51, 0.93);
  -moz-transition-timing-function:cubic-bezier(0, 0.98, 0.51, 0.93);
  -o-transition-timing-function:cubic-bezier(0, 0.98, 0.51, 0.93);
  transition-timing-function:cubic-bezier(0, 0.98, 0.51, 0.93);
  -webkit-transition-duration:300ms;
  -moz-transition-duration:300ms;
  -o-transition-duration:300ms;
  transition-duration:300ms;
  -webkit-transition-delay:0s;
  -moz-transition-delay:0s;
  -o-transition-delay:0s;
  transition-delay:0s
}
.bottom-inOutSpread:hover:after {
  left:0%;
  right:0%
}
.bottom-inOutSpread:not(:hover):after {
  -webkit-transition-delay:0s;
  -moz-transition-delay:0s;
  -o-transition-delay:0s;
  transition-delay:0s
}
