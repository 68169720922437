.footer {
  height: 277px;
  background: #34404D;
  color: #fff;
  display: flex;
  padding: 47px 0 0 0 ;
  justify-content: center;
  &-left {
     &-p {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      &-cum {
        border-left: 1px solid #ffffff;
        height: 26px;
      }
    }


  }
  &-right{
    margin-left: 217px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-img{
      width: 100px;
      height: 100px;
      margin-bottom: 20px;
    }
  }
}
